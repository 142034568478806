import React from "react"
import SelectedLanguageContextProvider from "./src/context/languageContext"
import LanguageSwitcher from "./src/context/languageSwitcher"

const WrapPageElement = ({ children }) => {
  const totalLanguages = []
  if(process.env.GATSBY_LANG_LIST.includes("de")) {
    totalLanguages.push({ key: "de", text: "Deutsch" })
  }
  if(process.env.GATSBY_LANG_LIST.includes("fr")) {
    totalLanguages.push({ key: "fr", text: "Français" })
  }
  if(process.env.GATSBY_LANG_LIST.includes("en")) {
    totalLanguages.push({ key: "en", text: "Englisch" })
  }
  let langPath = "de"
  if (typeof window !== 'undefined') langPath = window.location.pathname.substring(1, 3)

  
  return (
    <SelectedLanguageContextProvider langProps={totalLanguages} selectedLanguageProp={langPath}>
      <LanguageSwitcher>
        {children}
      </LanguageSwitcher>
    </SelectedLanguageContextProvider>
  )
}

export default WrapPageElement
