import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelectedLanguage } from "./languageContext"
import { navigate } from "gatsby"
import { IntlProvider } from "react-intl"
import translations from "../lang"
import { useStaticQuery, graphql } from "gatsby"


const LanguageSwitcher = ({ children }) => {
  const { selectedLanguage, setSelectedLanguage } = useSelectedLanguage()
  const { allPageLink } = useStaticQuery(
    graphql`
      query {
        allPageLink {
          nodes {
            link
            pageID
          }
        }
      }
    `
  )

  useEffect(() => {
    //const pathName = window.location.pathname
    const langPath = window.location.pathname.substring(1, 3)
    if (!selectedLanguage) {
      setSelectedLanguage(langPath)
    } else {
      if(langPath === selectedLanguage) {
        console.log("languageSwitcher langPath === selectedLanguage")
        return
      }
      const pageKey = allPageLink?.nodes.find(node => node.link === window.location.pathname)?.pageID;
      //const seoFallback = seoFallbackNodes.find(node => node.locale === lang)?.seoFallback;


      // if(pathName === "/fr/" || pathName === "/fr") {
      //   navigate("/"+selectedLanguage)
      // }
      // if(pathName === "/de/" || pathName === "/de") {
      //   navigate("/"+selectedLanguage)
      // }
      // if(pathName === "/en/" || pathName === "/en") {
      //   navigate("/"+selectedLanguage)
      // }
      //console.log("allPageLink " + JSON.stringify(allPageLink))
      let foundLocalizedPage = false
      allPageLink.nodes.map(page => {
        const languageKey = page.link.substring(1, 3)
        if (page.pageID === pageKey && languageKey === selectedLanguage) {
          console.log("Navigate To" + JSON.stringify(page.link))
          navigate(page.link)
          //clear history, not really working
          //window.history.replaceState(null, null, `/${selectedLanguage}`);
          foundLocalizedPage = true
        }
      })
      if(!foundLocalizedPage) {
        console.log("Could not find localized page, navigating to /"+selectedLanguage)
        navigate("/"+selectedLanguage)
      }
    }
  }, [selectedLanguage])

  return (
    <IntlProvider
      defaultLocale={'de'}
      locale={selectedLanguage}
      messages={translations[selectedLanguage]}
    >
      {children}
    </IntlProvider>
  )
}

LanguageSwitcher.propTypes = {
  children: PropTypes.object.isRequired,
}

export default LanguageSwitcher
