import en from "./en.json"
import de from "./de.json"
import fr from "./fr.json"
const translations = {
  en,
  de,
  fr,
}

export default translations
