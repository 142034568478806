import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
export const defaultLangContext = {
  selectedLanguage: "de",
  availableLanguages: [],
  setSelectedLanguage: () => { },
  localizeRoutePath: () => { },
}

const SelectedLanguageContext = React.createContext(defaultLangContext)

export const useSelectedLanguage = () => useContext(SelectedLanguageContext)

const totalLanguages = [
  { key: "de", text: "Deutsch" },
  { key: "en", text: "English" },
  { key: "fr", text: "Français" },
]

const SelectedLanguageContextProvider = ({ children, langProps, selectedLanguageProp }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(selectedLanguageProp)
  const [languages, setLanguages] = useState(langProps)

  const initAvailableLanguages = () => {
    const langConfig = process.env.GATSBY_LANG_LIST
    let languagesList = []
    totalLanguages.forEach(element => {
      if (langConfig !== undefined && langConfig.includes(element.key)) {
        languagesList.push(element)
      }
    })

    setLanguages(languagesList)
  }

  useEffect(() => {
    if (languages?.length === 0) {
      initAvailableLanguages()
    }
  }, [selectedLanguage])

  return (
    <SelectedLanguageContext.Provider
      value={{
        selectedLanguage,
        availableLanguages: languages,
        setSelectedLanguage: language => setSelectedLanguage(language),
      }}
    >
      {children}
    </SelectedLanguageContext.Provider>
  )
}

SelectedLanguageContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
}

export default SelectedLanguageContextProvider
